import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from "../../components/layout/Layout";
import { BlueBtn } from "../../components/layout/styledComponents.js";
import Seo from "../../components/seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout mdxType="Layout">
  <Seo title="Initial Consultation - pikespeakoralsurgery" description="Your initial consultation appointment at Pikes Peak Oral Surgery & Dental Implant Center will consist of an explanation of your diagnosis and treatment options" mdxType="Seo" />
  <section className="container" style={{
        margin: '128px auto'
      }}>
        <p>{`Your initial appointment will consist of a consultation explaining your diagnosis and treatment options. Occasionally, surgery can be done the same day as the consultation. However, a complex medical history or treatment plan will require an evaluation and a second appointment to provide treatment on another day.`}</p>
        <p>{`Please assist us by providing the following information at the time of your consultation:`}</p>
        <ul>
          <li parentName="ul">{`Your surgical referral slip and any X-rays if applicable`}</li>
          <li parentName="ul">{`A list of medications and dosages you are presently taking`}</li>
          <li parentName="ul">{`If you have medical or dental insurance, bring the necessary completed forms. This will save time and allow us to help you process any claims.`}</li>
        </ul>
        <h3>{`IMPORTANT:`}</h3>
        <p>{`A parent or guardian must accompany all patients under the age of 18 years of age at the consultation visit.`}</p>
        <p>{`A pre-operative consultation and physical examination is mandatory for patients undergoing IV Sedation and General Anesthesia for surgery. Please have nothing to eat or drink (even water) after the midnight prior to your surgery. The exception to this is that we want you to take all normal home medications on schedule with a small sip of water only. You will also need a responsible adult to drive you home and care for you the rest of the day.`}</p>
        <p>{`Please alert the office if you have a medical condition that may be of concern before surgery (e.g., diabetes, a heart condition, artificial heart valves or artificial joints, on blood thinners, or have liver or kidney disease, etc.) or if you are on any medication (e.g., heart medications, insulin, anticoagulant therapy, or have been on a bisphosphonate medication for osteoporosis or cancer treatment, etc.).`}</p>
        <h3>{`X-ray`}</h3>
        <p>{`If your dentist or physician has taken X-rays, you may request that they forward them to our office. If there is not enough time, please pick them up and bring them to our office. If additional films are necessary, they can be taken at our facility. Also, your doctor has the option of sending your information to us via e-mail.`}</p>
        <a href="#contact" style={{
          display: "block",
          margin: "64px auto 0",
          width: "max-content"
        }}>
  <BlueBtn mdxType="BlueBtn">Book an appointment now</BlueBtn>
        </a>
      </section>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      